// services/truckService.js

import { logout } from './authService'; // Ensure logout function is accessible

// Helper function to handle API requests
const fetchWithAuth = async (url, token) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 401 || response.status === 403) {
      logout(); // Logout on unauthorized or forbidden access
      throw new Error('Session expired or access denied. You have been logged out.');
    }

    if (!response.ok) {
      console.error(`Failed to fetch from ${url}:`, response);
      return [];
    }

    return await response.json();
  } catch (error) {
    console.error(`Error fetching from ${url}:`, error);
    return [];
  }
};

// Fetch trucks
export const fetchTrucks = async () => {
  const token = localStorage.getItem('accessToken'); // Retrieve token from localStorage
  const url = 'https://api.diesel.truckmemo.com/api/transporter/2/trucks';
  return await fetchWithAuth(url, token);
};