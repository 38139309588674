import { logout } from './authService'; // Import the logout function

const BASE_URL = 'https://api.diesel.truckmemo.com/api/transporter/2';

export const DEFAULT_STATION_NAMES = 'Pilot,Flying';

// Helper function to get the token from localStorage
const getToken = () => localStorage.getItem('accessToken');

// Helper function for making API requests
const fetchWithAuth = async (url, options = {}) => {
  try {
    const token = getToken();
    const headers = {
      Authorization: `Bearer ${token}`,
      ...options.headers,
    };

    const response = await fetch(url, { ...options, headers });

    if (response.status === 401 || response.status === 403) {
      logout(); // Call logout on unauthorized or forbidden
      throw new Error('Session expired or access denied. You have been logged out.');
    }

    if (!response.ok) {
      console.error('Failed to fetch:', response);
      return [];
    }

    return await response.json();
  } catch (err) {
    console.error('Error fetching:', err);
    return [];
  }
};

// Fetch gas stations with query parameters
export const fetchGasStations = async (lat, lng, distance = 1, searchMode = 'tracked') => {
  const url = `${BASE_URL}/stations?lat=${lat}&lng=${lng}&distance=${distance}&searchMode=${searchMode}&stationNameFilter=${encodeURIComponent(DEFAULT_STATION_NAMES)}`;
  return await fetchWithAuth(url);
};

// Fetch all tracked gas stations by name filter
export const fetchAllTrackedGasStations = async () => {
  // const stationNames = 'Pilot,Flying,Love’s,TA,TravelCenters,Petro,Road Ranger,Sapp Bros,Kwik,7-Eleven Truck,Circle,Quick Fuel,Buc-ee,DASH,Speedway,Murphy';
  // const stationNames = 'Pilot,Flying'; // Simplified filter for this request
  const url = `${BASE_URL}/stations/search?stationNameFilter=${encodeURIComponent(DEFAULT_STATION_NAMES)}`;
  return await fetchWithAuth(url);
};
