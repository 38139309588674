import { logout } from './authService'; // Import the logout function

export const fetchCompanyDetails = async (token) => {
  try {
    const response = await fetch('https://api.diesel.truckmemo.com/api/transporter/2', {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.status === 401 || response.status === 403) {
      logout();  // Log out on unauthorized or forbidden status
      throw new Error('Session expired or access denied. You have been logged out.');
    }

    if (!response.ok) {
      throw new Error('Failed to fetch company details');
    }

    // Directly return the parsed JSON without redundant 'await'
    return response.json();
  } catch (error) {
    console.error('Error fetching company details:', error.message);
    throw error;
  }
};