/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import { fetchTrucks } from '../services/truckService';
import { fetchGasStations, fetchAllTrackedGasStations } from '../services/gasStationService';
import { Box, CircularProgress, LinearProgress, useTheme } from '@mui/material';
import TruckDetailsComponent from './TruckDetailsComponent';
import GasStationDetailsComponent from './GasStationDetailsComponent'; 

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const center = {
  lat: 39.8283, // USA center
  lng: -98.5795,
};

const options = {
  disableDefaultUI: true,
  zoomControl: true,
  scrollwheel: true,
  gestureHandling: 'auto',
  draggable: true,
};

const MapComponent = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyASNWGjWIVemLz3_8Ir4asTX7AhQrwVapk',
  });

  const [trucks, setTrucks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [error, setError] = useState(null);
  const [selectedTruck, setSelectedTruck] = useState(null);
  const [selectedStation, setSelectedStation] = useState(null);
  const [gasStations, setGasStations] = useState([]);
  const [visibleStations, setVisibleStations] = useState([]); // State for visible stations
  const [zoomLevel, setZoomLevel] = useState(null); // Track zoom level
  const [mapCenter, setMapCenter] = useState(center); // Track map center

  const [northEastBound, setNorthEastBound] = useState(null);
  const [southWestBound, setSouthWestBound] = useState(null);

  const mapRef = useRef(null);


  const theme = useTheme();
  const [progressDirection, setProgressDirection] = useState('right');

  // Toggle progress direction for animation effect
  useEffect(() => {
    if (loading || refreshing) {
      const interval = setInterval(() => {
        setProgressDirection((prev) => (prev === 'right' ? 'left' : 'right'));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [loading, refreshing]);


  const fetchTruckData = async (searchMode = 'min', distance = 1) => {
    if (refreshing) {
      return;
    }

    setRefreshing(true);

    try {
      const token = localStorage.getItem('accessToken');
      const truckData = await fetchTrucks(token);
      console.log('Truck data:', truckData);
      setTrucks(truckData);
      setError(null);

      // Create a hash map to store gas stations by their coordinates
      // const stationMap = new Map();

      // // Initialize the map with existing gas stations (if any)
      // gasStations.forEach(station => {
      //   const key = `${station.latitude},${station.longitude}`;
      //   stationMap.set(key, station);
      // });

      // fetchTrackedGasStations();

      // const newStations = await fetchAllTrackedGasStations();
      // setGasStations(newStations);

      console.log('Fetching gas stations...', refreshing, loading);
      await fetchTrackedGasStations();
      console.log('Fetching gas stations... done', refreshing, loading);
      
      // setVisibleStations(newStations);

      // Fetch gas stations for each truck and update the state immediately
      // truckData.forEach(truck => {
      //   if (truck.stats?.fuelPercent?.value > 30) {
      //     console.log('Truck has enough fuel:', truck.name);
      //     return;
      //   } else {
      //     console.log('Fetching gas stations for:', truck.name);
      //   }

      //   fetchGasStations(truck.location.latitude, truck.location.longitude, distance, searchMode)
      //     .then(newStations => {
      //       console.log('Gas stations:', newStations);
      //       newStations.forEach(newStation => {
      //         const key = `${newStation.latitude},${newStation.longitude}`;

      //         // Add or update the station in the map
      //         stationMap.set(key, newStation);
      //       });

      //       // Convert the map back to an array and update the state
      //       setGasStations(Array.from(stationMap.values()));
      //       updateVisibleStations(); // Update visible stations after setting gas stations
      //     })
      //     .catch(err => {
      //       console.error('Error fetching gas stations:', err);
      //     });
      // });

    } catch (err) {
      setError('Failed to load truck or gas station data');
      console.error('Data fetch error:', err);
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  // Function to track zoom level and filter visible stations
  const handleZoomChanged = () => {
    if (mapRef.current) {
    //   const zoom = mapRef.current.getZoom();
    //   setZoomLevel(zoom);

    //   console.log('Zoom level:', zoom);

    //   // Adjust behavior based on zoom level
    //   if (zoom > 12) {
    //     // Fetch gas stations for current map center within a radius
    //     fetchGasStationsByCoords(mapCenter.lat, mapCenter.lng, Math.abs(24 - zoom), 'all');
    //   } else if (zoom > 8) {
    //     // Fetch gas stations for current map center within a radius
    //     // fetchGasStationsByCoords(mapCenter.lat, mapCenter.lng, Math.abs(24 - zoom), 'all');
    //     fetchTrackedGasStations();
    //   } else {
    //     // Hide gas stations if zoom level is too low
    //     // setVisibleStations([]);
    //     // fetchTruckData('tracked', 1000);
    //     fetchTrackedGasStations();
    //   }
      updateVisibleStations(gasStations);
    }
  };

// Helper function to calculate the distance between two coordinates (Haversine formula) in miles
const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 3958.8; // Earth radius in miles
  const φ1 = lat1 * (Math.PI / 180);
  const φ2 = lat2 * (Math.PI / 180);
  const Δφ = (lat2 - lat1) * (Math.PI / 180);
  const Δλ = (lon2 - lon1) * (Math.PI / 180);

  const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const distance = R * c; // Distance in miles
  return distance;
};

// Generic function to detect duplicates based on coordinates
// const detectDuplicateCoords = (arr) => {
//   const seen = new Map();
//   const duplicates = [];

//   arr.forEach(item => {
//     const key = `${item.latitude},${item.longitude}`;
    
//     if (seen.has(key)) {
//       duplicates.push(item); // If key already exists, it's a duplicate
//     } else {
//       seen.set(key, item); // Otherwise, add the key to the map
//     }
//   });

//   if (duplicates.length > 0) {
//     console.error('Duplicate coordinates found:', duplicates);
//   }

//   return duplicates;
// };

const convertPriceToNumber = (price) => {
  if (typeof price === 'string') {
    // Use regex to remove any non-numeric characters except for the decimal point and negative sign
    const cleanedPrice = price.replace(/[^0-9.-]+/g, "");
    return parseFloat(cleanedPrice);
  } else 
  return price; // Return null if the input is not a string
};

// Updated function to group nearby stations and update visible stations
// const updateVisibleStations = (gasStations) => {
//   // setVisibleStations(gasStations);
//   // setLoading(false);

//   if (!gasStations || gasStations.length === 0) {
//     console.error('??????????????????????????????????????????????????????? No gas stations available');
//     // fetchTrackedGasStations();
//     return;
//   }

  
  
//   if (mapRef.current) {
//     const bounds = mapRef.current.getBounds();

//     if (bounds) {
//       // Filter stations that are within the map bounds
//       const stationsInBounds = gasStations.filter(station => {
//         const latLng = new window.google.maps.LatLng(station.latitude, station.longitude);
//         return bounds.contains(latLng);
//       });

//       const groupedStations = [];
//       stationsInBounds.forEach(station => {
//         let isGrouped = false;

//         // Check if this station is too close to any already grouped station
//         for (let group of groupedStations) {
//           const distance = calculateDistance(
//             station.latitude, station.longitude,
//             group.latitude, group.longitude
//           );

//           console.log('zoomLevel:', zoomLevel, distance, groupedStations.length);

//           if (distance > Math.abs(1000)) { // Threshold in meters (adjust as needed)
//             // If it's close, update the group to show the station with the lower price
//             if (convertPriceToNumber(station.price) < convertPriceToNumber(group.price)) {
//               console.log('Updating group:', station.station, group.station);
//               group.latitude = station.latitude;
//               group.longitude = station.longitude;
//               group.price = station.price;
//               group.station = station.station;
//               group.address = station.address;
//             }
//             isGrouped = true;
//             break;
//           }
//         }

//         // If the station wasn't grouped, add it to the groupedStations array
//         if (!isGrouped) {
//           groupedStations.push({ ...station });
//         }
//       });

//       setVisibleStations(groupedStations);
//     }

//     // setVisibleStations(gasStations);
//   }

// const updateVisibleStations = (newGasStations) => {
//   if (!newGasStations || newGasStations.length === 0) {
//     console.error('No gas stations available');
//     return;
//   }

//   setGasStations(newGasStations);

//   if (mapRef.current) {
//     const bounds = mapRef.current.getBounds();

//     if (bounds) {
//       // Filter stations that are within the map bounds
//       const stationsInBounds = newGasStations.filter(station => {
//         const latLng = new window.google.maps.LatLng(station.latitude, station.longitude);
//         return bounds.contains(latLng);
//       });

//       const zoomLevel = mapRef.current.getZoom(); // Get current zoom level

//       // Number of grid cells (increase the number at higher zoom levels)
//       const gridSize = zoomLevel >= 15 ? 10 : zoomLevel >= 10 ? 5 : 2; // Adjust based on zoom
//       const minLatStep = 0.01; // Minimum latitude step size to prevent too many cells
//       const minLngStep = 0.01; // Minimum longitude step size to prevent too many cells

//       // if (!northEastBound) {
//       //   setNorthEastBound(bounds.getNorthEast().toJSON());
//       // }

//       // if (!southWestBound) {
//       //   setSouthWestBound(bounds.getSouthWest().toJSON());
//       // }

//       // Get bounds coordinates
//       const northEast = bounds.getNorthEast();
//       const southWest = bounds.getSouthWest();

//       console.log('======================================= ', 'northEast:', {lat: northEast.lat(), lng: northEast.lng()}, ' southWest:', {lat: southWest.lat(), lng: southWest.lng()});

//       // Calculate latitude and longitude steps
//       const latStep = Math.max((northEast.lat() - southWest.lat()) / gridSize, minLatStep);
//       const lngStep = Math.max((northEast.lng() - southWest.lng()) / gridSize, minLngStep);

//       const groupedStations = new Map();

//       stationsInBounds.forEach(station => {
//         const latIndex = Math.floor((station.latitude - southWest.lat()) / latStep);
//         const lngIndex = Math.floor((station.longitude - southWest.lng()) / lngStep);

//         const gridKey = `${latIndex}-${lngIndex}`; // Unique key for each grid cell

//         if (!groupedStations.has(gridKey)) {
//           // Add station if it's the first in this grid cell
//           groupedStations.set(gridKey, { ...station });
//         } else {
//           // Update with the station having the minimum price in this grid cell
//           const existingStation = groupedStations.get(gridKey);
//           if (convertPriceToNumber(station.price) < convertPriceToNumber(existingStation.price)) {
//             groupedStations.set(gridKey, { ...station });
//           }
//         }
//       });

//       // Convert the grouped stations Map to an array
//       const resultStations = Array.from(groupedStations.values());

//       console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ Visible stations:', resultStations.length, zoomLevel, {latStep, lngStep});

//       setVisibleStations(resultStations);
//     }
//   }

const updateVisibleStations = (newGasStations) => {
  if (!newGasStations || newGasStations.length === 0) {
    console.error('No gas stations available');
    return;
  }

  setGasStations(newGasStations);

  if (mapRef.current) {
    const bounds = mapRef.current.getBounds();
    const zoomLevel = mapRef.current.getZoom(); // Get the current zoom level

    // Calculate grid size smoothly using exponential decay based on zoom level
    const baseGridSize = 60; // Default base grid size
    const scaleFactor = 0.1; // Controls how fast grid size decreases
    const minGridSize = 0.05; // Minimum grid size to prevent too many cells
    const gridSize = baseGridSize * Math.pow(scaleFactor, zoomLevel / 5); // Smooth grid size calculation

    // console.log('Zoom level:', zoomLevel, 'Grid size:', gridSize);

    // Get bounds coordinates
    // const northEast = bounds.getNorthEast();
    // const southWest = bounds.getSouthWest();

    // Filter stations within the bounds
    const stationsInBounds = newGasStations.filter(station => {
      const latLng = new window.google.maps.LatLng(station.latitude, station.longitude);
      return bounds.contains(latLng);
    });

    const groupedStations = new Map();

    // Group stations within the visible bounds based on calculated grid size
    stationsInBounds.forEach(station => {
      // Convert latitude and longitude to grid index
      const latIndex = gridSize <= minGridSize ? station.latitude : Math.floor(station.latitude / gridSize);
      const lngIndex = gridSize <= minGridSize ? station.longitude : Math.floor(station.longitude / gridSize);
      
      // console.log('gridSize <= minGridSize', gridSize <= minGridSize, lngIndex, latIndex);

      // Create a unique grid key for this station
      const gridKey = `${latIndex}-${lngIndex}`;

      if (!groupedStations.has(gridKey)) {
        // If no station exists in this grid, add the current one
        groupedStations.set(gridKey, { ...station });
      } else {
        // If a station already exists in this grid, choose the one with the lower price
        const existingStation = groupedStations.get(gridKey);
        if (convertPriceToNumber(station.price) <= convertPriceToNumber(existingStation.price)) {
          groupedStations.set(gridKey, { ...station });
        }
      }
    });

    // Convert the grouped stations Map to an array
    const resultStations = Array.from(groupedStations.values());

    // console.log('Visible stations:', resultStations.length, 'Zoom level:', zoomLevel, 'Grid Size:', gridSize);
    setVisibleStations(resultStations);
  }


// const updateVisibleStations = (newGasStations) => {
//   if (!newGasStations || newGasStations.length === 0) {
//     console.error('No gas stations available');
//     return;
//   }

//   setGasStations(newGasStations);

//   if (mapRef.current) {
//     const bounds = mapRef.current.getBounds();

//     if (bounds) {
//       // Filter stations that are within the map bounds
//       const stationsInBounds = newGasStations.filter(station => {
//         const latLng = new window.google.maps.LatLng(station.latitude, station.longitude);
//         return bounds.contains(latLng);
//       });

//       const zoomLevel = mapRef.current.getZoom(); // Get current zoom level

//       // Number of grid cells (increase the number at higher zoom levels)
//       const gridSize = zoomLevel >= 15 ? 10 : zoomLevel >= 10 ? 5 : 2; // Adjust based on zoom
//       const minLatStep = 0.01; // Minimum latitude step size to prevent too many cells
//       const minLngStep = 0.01; // Minimum longitude step size to prevent too many cells

//       // Calculate latitude and longitude steps (fixed relative to a global origin)
//       const latStep = Math.max(180 / gridSize, minLatStep); // Total latitude span is 180 degrees (from -90 to 90)
//       const lngStep = Math.max(360 / gridSize, minLngStep); // Total longitude span is 360 degrees (from -180 to 180)

//       const groupedStations = new Map();

//       stationsInBounds.forEach(station => {
//         // Calculate the absolute grid indices based on the station's latitude and longitude
//         const latIndex = Math.floor((station.latitude + 90) / latStep);  // Shift latitude from [-90, 90] to [0, 180]
//         const lngIndex = Math.floor((station.longitude + 180) / lngStep); // Shift longitude from [-180, 180] to [0, 360]

//         const gridKey = `${latIndex}-${lngIndex}`; // Unique key for each grid cell

//         if (!groupedStations.has(gridKey)) {
//           // Add station if it's the first in this grid cell
//           groupedStations.set(gridKey, { ...station });
//         } else {
//           // Update with the station having the minimum price in this grid cell
//           const existingStation = groupedStations.get(gridKey);
//           if (convertPriceToNumber(station.price) < convertPriceToNumber(existingStation.price)) {
//             groupedStations.set(gridKey, { ...station });
//           }
//         }
//       });

//       // Convert the grouped stations Map to an array
//       const resultStations = Array.from(groupedStations.values());

//       console.log('Visible stations:', resultStations.length, zoomLevel, { latStep, lngStep });

//       setVisibleStations(resultStations);
//     }
//   }


  // if (mapRef.current) {
  //   const bounds = mapRef.current.getBounds();
  //   if (bounds || zoomLevel < 12) {
  //     console.log('Zoom level:', zoomLevel);
  //     if (zoomLevel < 6) {
  //       console.log('Zoom level:', zoomLevel);
  //       // setVisibleStations([]);
  //       setLoading(false);
  //       return;
  //     }

  //     // Filter stations that are within the map bounds
  //     const stationsInBounds = gasStations.filter(station => {
  //       const latLng = new window.google.maps.LatLng(station.latitude, station.longitude);
  //       return bounds.contains(latLng);
  //     });

  //     const groupedStations = [];

  //     if (zoomLevel < 12) {
  //       // setVisibleStations(stationsInBounds);
  //       setLoading(false);
  //       return;
  //     }

  //     stationsInBounds.forEach(station => {
  //       let isGrouped = false;

  //       // Check if this station is too close to any already grouped station
  //       for (let group of groupedStations) {
  //         const distance = calculateDistance(
  //           station.latitude, station.longitude,
  //           group.latitude, group.longitude
  //         );

  //         // console.log('zoomLevel:', zoomLevel);

  //         if (distance > Math.abs(2 * zoomLevel)) { // Threshold in meters (adjust as needed)
  //           // If it's close, update the group to show the station with the lower price
  //           if (convertPriceToNumber(station.price) < convertPriceToNumber(group.price)) {
  //             group.latitude = station.latitude;
  //             group.longitude = station.longitude;
  //             group.price = station.price;
  //             group.station = station.station;
  //             group.address = station.address;
  //           }
  //           isGrouped = true;
  //           break;
  //         }
  //       }

  //       // If the station wasn't grouped, add it to the groupedStations array
  //       if (!isGrouped) {
  //         groupedStations.push({ ...station });
  //       }
  //     });

  //     // Update the state with grouped stations
  //     // detectDuplicateCoords(groupedStations);
  //     // setVisibleStations(groupedStations);
  //     // setVisibleStations(stationsInBounds);
  //     setLoading(false);
  //   } else {
  //     // setVisibleStations([]);
  //     setLoading(false);
  //     // fetchTrackedGasStations();
  //     console.error('--------------------------------------- Map bounds not available');
  //   }
  // }
};

  // const fetchGasStationsByCoords = async (latitude, longitude, radius = 5, searchMode = 'min') => {
  //   try {
  //     const newStations = await fetchGasStations(latitude, longitude, radius, searchMode);

  //     if (!newStations || newStations.length === 0) {
  //       console.error('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^ No gas stations available');
  //     }

  //     console.log('Gas stations based on current coordinates:', newStations);

  //     // const stationMap = new Map();
  //     // gasStations.forEach(station => {
  //     //   const key = `${station.latitude},${station.longitude}`;
  //     //   stationMap.set(key, station);
  //     // });

  //     // newStations.forEach(newStation => {
  //     //   const key = `${newStation.latitude},${newStation.longitude}`;
  //     //   stationMap.set(key, newStation);
  //     // });

  //     // setGasStations(Array.from(stationMap.values()));

  //     setGasStations(newStations);

  //     updateVisibleStations(); // Update visible stations after fetching gas stations
  //   } catch (err) {
  //     console.error('Error fetching gas stations by coordinates:', err);
  //   }
  // };

  const fetchTrackedGasStations = async () => {
    // if (refreshing) {
    //   return;
    // }

    // setRefreshing(true);


    try {
      const newStations = await fetchAllTrackedGasStations();

      console.log('Gas tracked gas stations:', newStations);

      // const stationMap = new Map();
      // gasStations.forEach(station => {
      //   const key = `${station.latitude},${station.longitude}`;
      //   stationMap.set(key, station);
      // });

      // newStations.forEach(newStation => {
      //   const key = `${newStation.latitude},${newStation.longitude}`;
      //   stationMap.set(key, newStation);
      // });

      // setGasStations(Array.from(stationMap.values()));

      if (newStations && newStations.length > 0) {
        // Process array to remove duplicates by latitude and longitude
        const uniqueStations = newStations.filter((station, index, self) => 
          index === self.findIndex((s) => 
          s.latitude === station.latitude && s.longitude === station.longitude
        ));

        // setGasStations(newStations);
        updateVisibleStations(uniqueStations); // Update visible stations after fetching gas stations
      }
      
    } catch (err) {
      console.error('Error fetching tracked gas stations:', err);
    } finally {
      setRefreshing(false);
      setLoading(false);
    }
  };

  const onLoad = (map) => {
    mapRef.current = map;
    // updateVisibleStations([]); // Update visible stations when the map loads

    if (mapRef.current) {
      setMapCenter(mapRef.current.getCenter().toJSON()); // Update the map center
      // if (mapRef.current.getBounds()) {
      //   setNorthEast(mapRef.current.getBounds().getNorthEast().toJSON());
      //   setSouthWest(mapRef.current.getBounds().getSouthWest().toJSON());
      // }
    }

  };

  const onCenterChanged = () => {
    if (mapRef.current) {
      // setMapCenter(mapRef.current.getCenter().toJSON()); // Update the map center

      updateVisibleStations(gasStations);
    }
  };

  useEffect(() => {
    if (isLoaded) {
      fetchTruckData("tracked", 50);
      setLoading(false);
      const refreshData = async () => {
        console.log('----------------------------------------------------------> Refreshing data...');
        fetchTruckData('tracked', 50);
        setLoading(false);
      };
      const intervalId = setInterval(refreshData, 10 * 60 * 1000); // Refresh every 10 minutes
      return () => clearInterval(intervalId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoaded]);

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress size={60} />
      </Box>
    );
  }

  const getTruckIcon = (truck) => {
    const { speed, heading } = truck.location;
    const fuelLevel = truck.stats?.fuelPercent?.value || 100;
    const isSelected = selectedTruck && selectedTruck.id === truck.id;
    
    let fillColor = '#4CAF50'; // Default green for moving trucks
    let strokeColor = isSelected ? '#000000' : '#FFFFFF'; // Black stroke for selected trucks, white otherwise

    if (fuelLevel < 10) {
      fillColor = speed === 0 ? '#CD5C5C' : '#F44336'; // Red for low fuel, grayish red if stopped
    } else if (fuelLevel < 30) {
      fillColor = speed === 0 ? '#DDAA33' : '#EECC37'; // Yellow for medium fuel, grayish yellow if stopped
    } else if (speed === 0) {
      fillColor = '#A0A0A0'; // Grey for stopped trucks
    }

    return {
      path: speed === 0 || heading === 0 
        ? 'M -8, 0 A 8,8 0 1,1 8,0 A 8,8 0 1,1 -8,0' 
        : 'M 0,-15 L 10,10 L -10,10 Z',
      fillColor,
      fillOpacity: 1,
      scale: 1.5,
      strokeWeight: 2,
      strokeColor,
      rotation: speed !== 0 ? heading : undefined,
      anchor: new window.google.maps.Point(0, 0),
    };
  };

  const getStationIcon = (station, id) => {

    if (id !== `${station.latitude},${station.longitude}`) {
      return;
    }

    // const realStation = visibleStations.find(s => s.latitude === station.latitude && s.longitude === station.longitude);
    const isSelected = selectedStation && selectedStation.latitude === station.latitude && selectedStation.longitude === station.longitude && selectedStation.price === station.price && selectedStation.station === station.station;

    if (isSelected) {
      console.log('Selected station:', selectedStation, station, id, `${station.latitude},${station.longitude}`);
    }

    let strokeColor = isSelected ? '#000000' : '#FFFFFF'; // Black stroke for selected trucks, white otherwise

    return ({
      path: 'M 11.1815 4.1504 C 7.5897 4.1504 4.6968 7.043 4.6968 10.637 V 29.053 C 4.6968 32.645 7.5897 35.54 11.1815 35.54 H 18.9355 L 25.1895 45.9635 L 31.4435 35.54 H 39.1955 C 42.7875 35.54 45.682 32.645 45.682 29.053 V 10.637 C 45.682 7.045 42.7875 4.1504 39.1955 4.1504 Z',
      fillColor: '#1E90FF',
      fillOpacity: 1,
      scale: 1,
      strokeWeight: 2,
      strokeColor: strokeColor,
      labelOrigin: new window.google.maps.Point(25, 20),
      anchor: new window.google.maps.Point(25, 45),
    })
  };

  const showDetailsWidget = (type, selected) => {
    switch (type) {
      case 'truck':
        setSelectedTruck(selected);
        setSelectedStation(null); // Clear selected station when showing truck details
        return;
      case 'station':
        setSelectedStation(selected);
        setSelectedTruck(null); // Clear selected truck when showing station details
        return
      default:
        return null;
    }
  }

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      {/* Progress Bar */}
      {(loading || refreshing) && (
        <LinearProgress
          sx={{
            top: 0,
            left: 0,
            width: '100%',
            height: '3px',
            animation: `moveProgressBar 1s ${progressDirection} infinite`,
            backgroundColor: 'transparent',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#003366',
            },
          }}
        />
      )}
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={5.2}
        center={center}
        options={options}
        onLoad={onLoad}
        onZoomChanged={handleZoomChanged}
        onCenterChanged={onCenterChanged} // Track center changes to update gas stations
      >
        {trucks.map((truck) => (
          <Marker
            key={truck.id}
            position={{ lat: truck.location.latitude, lng: truck.location.longitude }}
            icon={getTruckIcon(truck)}
            onClick={() => showDetailsWidget("truck", truck)}
          />
        ))}

        {visibleStations.map(station => (
          <Marker
            key={`${station.station}:${station.latitude},${station.longitude}=${station.price}`}
            position={{ lat: station.latitude, lng: station.longitude }}
            icon={getStationIcon(station, `${station.latitude},${station.longitude}`)}
            onClick={() => showDetailsWidget("station", station)}
            label={{
              text: station.price.toString(),
              color: '#ffffff',
              fontSize: '12px',
              fontWeight: 'bold',
            }}
          />
        ))}

        {error && <div style={{ position: 'absolute', top: 10, left: 10, color: 'red' }}>{error}</div>}
      </GoogleMap>

      {selectedTruck && <TruckDetailsComponent truck={selectedTruck} onClose={() => setSelectedTruck(null)} />}
      {selectedStation && <GasStationDetailsComponent station={selectedStation} onClose={() => setSelectedStation(null)} />}
    </Box>
  );
};

export default MapComponent;