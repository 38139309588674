import React, { useEffect } from 'react';
import { Paper, Typography, Box, Divider, Button } from '@mui/material';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HomeIcon from '@mui/icons-material/Home';

const GasStationDetailsComponent = ({ station, onClose, onLoad }) => {

  useEffect(() => {
    if (onLoad) onLoad();
  }, [onLoad]);

  if (!station) return null;

  console.log('station:', station);

  const { price, latitude, longitude, address } = station;

  const convertPriceToNumber = (price) => {
    if (typeof price === 'string') {
      // Use regex to remove any non-numeric characters except for the decimal point and negative sign
      const cleanedPrice = price.replace(/[^0-9.-]+/g, "");
      return parseFloat(cleanedPrice);
    } else 
      return price; // Return null if the input is not a string
  };

  const handleLocationClick = () => {
    const mapUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
    window.open(mapUrl, '_blank');
  };

  return (
    <Paper
      elevation={4}
      sx={{
        position: 'absolute',
        top: 20,
        right: 20,
        padding: 3,
        backgroundColor: '#f7f9fc',
        width: 'auto',
        minWidth: '300px',
        borderRadius: '12px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        maxWidth: '500px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <LocalGasStationIcon sx={{ fontSize: 30, color: '#1E90FF', mr: 1 }} />
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            color: '#333',
            whiteSpace: 'wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {station.station || 'Gas Station'}
        </Typography>
      </Box>

      <Divider sx={{ marginBottom: 2 }} />

      {/* Price Information */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <AttachMoneyIcon sx={{ color: '#FFD700', mr: 1 }} />
        <Typography variant="body1">
          <strong>Price:</strong> ${convertPriceToNumber(price.toString())}
        </Typography>
      </Box>

      {/* Address Information */}
      {address && (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <HomeIcon sx={{ color: '#4CAF50', mr: 1 }} />
          <Typography variant="body1">
            <strong>Address:</strong> {address}
          </Typography>
        </Box>
      )}

      {/* Location Information */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <LocationOnIcon sx={{ color: '#2196f3', mr: 1 }} />
        <Typography
          variant="body1"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <strong>Coordinates:</strong> {latitude}, {longitude}
        </Typography>
      </Box>

      {/* Action Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLocationClick}
          sx={{ textTransform: 'none' }}
        >
          Open Location in Map
        </Button>
        <Button variant="text" onClick={onClose}>
          Close
        </Button>
      </Box>
    </Paper>
  );
};

export default GasStationDetailsComponent;