import axios from 'axios';

// Function to handle login logic and store token
export const login = async (email, password) => {
  try {
    const response = await axios.post('https://api.diesel.truckmemo.com/auth/login', {
      email,
      password,
    }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Store accessToken and expirationTime in localStorage
    const { accessToken, expirationTime } = response.data;
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('expirationTime', expirationTime);
    localStorage.setItem('email', email);

    return response.data;
  } catch (error) {
    throw new Error('Login failed. Please check your credentials.');
  }
};

// Function to check if the user is authenticated
export const isAuthenticated = () => {
  const accessToken = localStorage.getItem('accessToken');
  const expirationTime = localStorage.getItem('expirationTime');
  const currentTime = new Date().getTime();

  return accessToken && expirationTime > currentTime;
};

// Function to log out the user
export const logout = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('expirationTime');
};