import React, { useState, useEffect } from 'react';
import { useNavigate, Route, Routes } from 'react-router-dom';
import { isAuthenticated, logout } from '../services/authService';
import { fetchUsers, getUserByEmail } from '../services/userService';
import { fetchCompanyDetails } from '../services/companyService';
import { useMediaQuery, AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Avatar, Box, CssBaseline, Drawer, List, ListItemText, ListItemIcon, Divider, ListItemButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { deepPurple } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import ProfilePage from './ProfilePage';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/Logout';
import AboutPage from './AboutPage';
import MapComponent from './MapComponent';

const drawerWidth = 240;

const HomePage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const [mobileOpen, setMobileOpen] = useState(isLargeScreen); // Default open on large screens
  const [anchorEl, setAnchorEl] = useState(null); // For the dropdown menu
  const [user, setUser] = useState(null); // Store the logged-in user details
  const [initials, setInitials] = useState(''); // User initials
  const [companyDetails, setCompanyDetails] = useState(null); // Store company details

  // Redirect to login if not authenticated
  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login');
    } else {
      const storedToken = localStorage.getItem('accessToken');
      const email = localStorage.getItem('email');
      const expirationTime = localStorage.getItem('expirationTime');

      // Fetch user details
      fetchUsers(storedToken).then((users) => {
        const loggedInUser = getUserByEmail(users, email);
        if (loggedInUser) {
          setUser({ ...loggedInUser, expirationTime });
          const nameInitials = loggedInUser.name
            .split(' ')
            .map((namePart) => namePart.charAt(0))
            .join('');
          setInitials(nameInitials);
        }
      });

      // Fetch company details
      fetchCompanyDetails(storedToken).then((data) => {
        setCompanyDetails(data);
      });
    }
  }, [navigate]);

  // Update the state when screen size changes (keep drawer open on large screens by default)
  useEffect(() => {
    setMobileOpen(isLargeScreen);
  }, [isLargeScreen]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen); // Toggle the Drawer visibility
  };

  const handleDrawerClose = () => {
    setMobileOpen(false); // Close the drawer
  };

  const drawer = (
    <div
      style={{
        backgroundColor: '#2c2c2c',
        height: '100%',
        color: '#ffffff',
      }}
    >
      <Toolbar sx={{ backgroundColor: '#2c2c2c' }} />
      <Divider sx={{ backgroundColor: '#3a3a3a' }} />
      <List>
        <ListItemButton
          // button={true ? 1 : 0}
          onClick={() => navigate('/home')}
          sx={{
            '&:hover': {
              backgroundColor: '#3a3a3a',
            },
          }}
        >
          <ListItemIcon>
            <HomeIcon sx={{ color: '#ffffff' }} />
          </ListItemIcon>
          <ListItemText primary="Home" sx={{ color: '#ffffff' }} />
        </ListItemButton>
        <ListItemButton
          // button={true ? 1 : 0}
          onClick={() => navigate('/home/profile')}
          sx={{
            '&:hover': {
              backgroundColor: '#3a3a3a',
            },
          }}
        >
          <ListItemIcon>
            <AccountCircleIcon sx={{ color: '#ffffff' }} />
          </ListItemIcon>
          <ListItemText primary="Profile" sx={{ color: '#ffffff' }} />
        </ListItemButton>
        <ListItemButton
          // button={true ? 1 : 0}
          onClick={() => navigate('/home/about')}
          sx={{
            '&:hover': {
              backgroundColor: '#3a3a3a',
            },
          }}
        >
          <ListItemIcon>
            <InfoIcon sx={{ color: '#ffffff' }} />
          </ListItemIcon>
          <ListItemText primary="About" sx={{ color: '#ffffff' }} />
        </ListItemButton>
        <ListItemButton
          // button={true}
          onClick={handleLogout}
          sx={{
            '&:hover': {
              backgroundColor: '#3a3a3a',
            },
          }}
        >
          <ListItemIcon>
            <LogoutIcon sx={{ color: '#ffffff' }} />
          </ListItemIcon>
          <ListItemText primary="Logout" sx={{ color: '#ffffff' }} />
        </ListItemButton>
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex', height: '100vh', width: '100vw' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              fontWeight: 'bold',
              letterSpacing: 1.5,
              color: 'linear-gradient(to right, #4facfe, #00f2fe)',
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
            }}
          >
            Diesel Prices
          </Typography>
          {user && (
            <IconButton onClick={handleMenuClick} sx={{ ml: 'auto' }}>
              <Avatar sx={{ bgcolor: deepPurple[500] }}>{initials}</Avatar>
            </IconButton>
          )}
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem onClick={() => navigate('/home/profile')}>Profile</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>

      {/* Drawer - Toggled on click */}
      <Drawer
        variant="persistent"
        open={mobileOpen}
        onClose={handleDrawerClose} // Close the drawer when clicked outside
        ModalProps={{
          keepMounted: true,
          hideBackdrop: true, // Prevent the screen from darkening
        }}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: '#2c2c2c',
            color: '#ffffff',
          },
        }}
      >
        {drawer}
      </Drawer>

      {/* Main content area */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Toolbar />

        <Box
          sx={{
            flexGrow: 1,
            padding: 0,
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(8px)',
            height: '100vh',
            width: '100vw',
            overflow: 'auto', // Enable scrolling inside the content
          }}
        >
          <Routes>
            <Route path="profile" element={<ProfilePage user={user} companyDetails={companyDetails} />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="" element={<MapComponent />} />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
};

export default HomePage;