import React from 'react';
import { Box, Typography, Paper, Divider, Avatar } from '@mui/material';

const AboutPage = () => {
  return (
    <Box sx={{ padding: 3, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Paper
        elevation={4}
        sx={{
          padding: 4,
          borderRadius: 2,
          backgroundColor: '#f7f9fc',
          width: '100%',
          maxWidth: 600,
          textAlign: 'left', // Align text to the left
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        }}
      >
        {/* Wrapper Box to horizontally center the Avatar */}
        <Box 
          display="flex" 
          justifyContent="center" 
          sx={{ marginBottom: 2 }}
        >
          <Avatar
            alt="Diesel Prices Logo"
            src="/logo.png"
            variant="square"
            sx={{ width: 190, height: 90, marginBottom: 0, background: 'transparent' }}
          />
        </Box>
        <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#0056b3', marginBottom: 2, textAlign: 'center' }}>
          Diesel Prices
        </Typography>
        <Typography variant="body2" sx={{ color: '#999', marginBottom: 1, textAlign: 'center' }}>
          v1.0.1 (MVP)
        </Typography>

        <Divider sx={{ marginBottom: 3 }} />
        <Typography variant="body1" sx={{ color: '#888', marginBottom: 2 }}>
          Our mission is to help truck drivers and logistics companies reduce costs by providing real-time diesel price
          information. By locating the cheapest fuel near drivers on their routes, we aim to optimize fuel expenses and
          streamline logistics operations.
        </Typography>
        <Typography variant="body1" sx={{ color: '#888', marginBottom: 3 }}>
          Powered by MyLib Systems LLC, Diesel Prices empowers companies to make smarter, data-driven fuel choices
          and improve their operational efficiency.
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        <Typography variant="body2" sx={{ color: '#888', textAlign: 'center' }}>
          &copy; 2024 MyLib Systems LLC. All rights reserved.
        </Typography>
      </Paper>
    </Box>
  );
};

export default AboutPage;