import React, { useEffect } from 'react';
import { Paper, Typography, Box, Divider, Button } from '@mui/material';
import SpeedIcon from '@mui/icons-material/Speed';
import NavigationIcon from '@mui/icons-material/Navigation';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TruckIcon from '@mui/icons-material/LocalShipping';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation'; // Import fuel icon
import CloseIcon from '@mui/icons-material/Close'; // Import close icon

const TruckDetailsComponent = ({ truck, onClose, onLoad }) => {
  
  useEffect(() => {
    if (onLoad) onLoad();
  }, [onLoad]);

  if (!truck) return null;

  return (
    <Paper
      elevation={4}
      sx={{
        position: 'absolute',
        top: 20,
        right: 20,
        padding: 3,
        backgroundColor: '#f7f9fc',
        width: 'auto',
        minWidth: '300px',
        borderRadius: '12px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        maxWidth: '500px',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TruckIcon sx={{ fontSize: 30, color: '#4CAF50', mr: 1 }} />
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              color: '#333',
              whiteSpace: 'wrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {truck.name}
          </Typography>
        </Box>
        <Button onClick={onClose} sx={{ minWidth: 'auto' }}>
          <CloseIcon />
        </Button>
      </Box>

      <Divider sx={{ marginBottom: 2 }} />

      {/* Fuel Information */}
      {truck.stats && truck.stats.fuelPercent && (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <LocalGasStationIcon sx={{ color: '#556B2F', mr: 1 }} />
          <Typography variant="body1">
            <strong>Fuel:</strong> {truck.stats.fuelPercent.value}%
          </Typography>
        </Box>
      )}

      {/* Speed Information */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <SpeedIcon sx={{ color: '#f44336', mr: 1 }} />
        <Typography
          variant="body1"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <strong>Speed:</strong> {truck.location.speed} km/h
        </Typography>
      </Box>

      {/* Heading Information */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <NavigationIcon
          sx={{ color: '#ffa000', mr: 1, transform: `rotate(${truck.location.heading}deg)` }}
        />
        <Typography
          variant="body1"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <strong>Heading:</strong> {truck.location.heading}°
        </Typography>
      </Box>

      {/* Location Information */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <LocationOnIcon sx={{ color: '#2196f3', mr: 1 }} />
        <Typography
          variant="body1"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <strong>Location:</strong> {truck.location.reverseGeo.formattedLocation}
        </Typography>
      </Box>

      {/* Last Update Time Information */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <AccessTimeIcon sx={{ color: '#757575', mr: 1 }} />
        <Typography variant="body1">
          <strong>Last Update:</strong>{' '}
          {new Date(truck.location.time).toLocaleString()}
        </Typography>
      </Box>
    </Paper>
  );
};

export default TruckDetailsComponent;