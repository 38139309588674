// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/background3.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f6f8;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); /* Path to the background image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh; /* Full viewport height */
  backdrop-filter: blur(8px); /* Blurs the background */
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  width: 100vw;  /* Full viewport width */
  backdrop-filter: blur(8px); /* Blurs the background */
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,iCAAiC;EACjC,yBAAyB;EACzB,yDAAkD,EAAE,iCAAiC;EACrF,sBAAsB;EACtB,2BAA2B;EAC3B,4BAA4B;EAC5B,aAAa,EAAE,yBAAyB;EACxC,0BAA0B,EAAE,yBAAyB;AACvD;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa,EAAE,yBAAyB;EACxC,YAAY,GAAG,wBAAwB;EACvC,0BAA0B,EAAE,yBAAyB;AACvD;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,YAAY,EAAE,wBAAwB;EACtC,aAAa,EAAE,yBAAyB;EACxC,0CAA0C,EAAE,sCAAsC;EAClF,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  font-family: 'Roboto', sans-serif;\n  background-color: #f4f6f8;\n  background-image: url('../public/background3.jpg'); /* Path to the background image */\n  background-size: cover;\n  background-position: center;\n  background-repeat: no-repeat;\n  height: 100vh; /* Full viewport height */\n  backdrop-filter: blur(8px); /* Blurs the background */\n}\n\n.App {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh; /* Full viewport height */\n  width: 100vw;  /* Full viewport width */\n  backdrop-filter: blur(8px); /* Blurs the background */\n}\n\n.overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw; /* Full viewport width */\n  height: 100vh; /* Full viewport height */\n  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
