import axios from 'axios';
import { logout } from './authService'; // Ensure logout is imported

// Fetch users from the API
export const fetchUsers = async () => {
  const token = localStorage.getItem('accessToken'); // Retrieve token from localStorage

  try {
    const response = await axios.get(
      'https://api.diesel.truckmemo.com/api/transporter/2/users',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    // Handle 401 or 403 status by logging out the user
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      console.warn('Unauthorized access or session expired. Logging out...');
      logout();
      throw new Error('Session expired or access denied. You have been logged out.');
    }

    console.error('Error fetching users:', error);
    throw error;
  }
};

// Get user by email
export const getUserByEmail = (users, email) => {
  return users.find((user) => user.email === email);
};