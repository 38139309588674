import React from 'react';
import App from './App';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';

const theme = createTheme(); // Create a default theme
// Find the root element in the DOM
const container = document.getElementById('root');
// Create a root and render the App
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}> {/* Wrap the app with ThemeProvider */}
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
