import React from 'react';
import { Box, Typography, Divider, Paper, Grid } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIcon from '@mui/icons-material/Phone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BusinessIcon from '@mui/icons-material/Business';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import { DEFAULT_STATION_NAMES } from '../services/gasStationService'; // Import station names

const ProfilePage = ({ user, companyDetails }) => {
  return (
    <Box sx={{ padding: 3, maxWidth: '1200px' }}>
      {/* User Info Section */}
      <Paper elevation={4} sx={{ padding: 4, borderRadius: 2, backgroundColor: '#f7f9fc', marginBottom: 4, boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)' }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#0056b3', marginBottom: 3 }}>
          <PersonIcon sx={{ marginRight: 1, verticalAlign: 'middle' }} /> User Information
        </Typography>
        <Divider sx={{ marginBottom: 3 }} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" sx={{ color: '#888', display: 'inline-flex', alignItems: 'center' }}>
              <PersonIcon sx={{ marginRight: 1 }} /> Name:
            </Typography>
            <Typography variant="h6" sx={{ color: '#222' }}>{user?.name || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" sx={{ color: '#888', display: 'inline-flex', alignItems: 'center' }}>
              <EmailIcon sx={{ marginRight: 1 }} /> Email:
            </Typography>
            <Typography variant="h6" sx={{ color: '#222' }}>{user?.email || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" sx={{ color: '#888', display: 'inline-flex', alignItems: 'center' }}>
              <AccessTimeIcon sx={{ marginRight: 1 }} /> Session Expiration:
            </Typography>
            <Typography variant="h6" sx={{ color: '#222' }}>
              {user?.expirationTime ? new Date(parseInt(user.expirationTime, 10)).toLocaleString() : 'N/A'}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      {/* Company Info Section */}
      <Paper elevation={4} sx={{ padding: 4, borderRadius: 2, backgroundColor: '#f7f9fc', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)' }}>
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#0056b3', marginBottom: 3 }}>
          <BusinessIcon sx={{ marginRight: 1, verticalAlign: 'middle' }} /> Company Information
        </Typography>
        <Divider sx={{ marginBottom: 3 }} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" sx={{ color: '#888', display: 'inline-flex', alignItems: 'center' }}>
              <BusinessIcon sx={{ marginRight: 1 }} /> Company Name:
            </Typography>
            <Typography variant="h6" sx={{ color: '#222' }}>{companyDetails?.name || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" sx={{ color: '#888', display: 'inline-flex', alignItems: 'center' }}>
              <HomeIcon sx={{ marginRight: 1 }} /> Address:
            </Typography>
            <Typography variant="h6" sx={{ color: '#222' }}>{companyDetails?.address || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" sx={{ color: '#888', display: 'inline-flex', alignItems: 'center' }}>
              <PhoneIcon sx={{ marginRight: 1 }} /> Phone:
            </Typography>
            <Typography variant="h6" sx={{ color: '#222' }}>{companyDetails?.phone || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" sx={{ color: '#888', display: 'inline-flex', alignItems: 'center' }}>
              <EmailIcon sx={{ marginRight: 1 }} /> Email:
            </Typography>
            <Typography variant="h6" sx={{ color: '#222' }}>{companyDetails?.email || 'N/A'}</Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography
              variant="body1"
              sx={{ color: '#888', display: 'inline-flex', alignItems: 'center' }}
            >
              <LocalGasStationIcon sx={{ marginRight: 1 }} /> Diesel Stations:
            </Typography>
            <Typography variant="h6" sx={{ color: '#222' }}>
              {DEFAULT_STATION_NAMES}
            </Typography>
          </Grid>
        </Grid>
        
      </Paper>
    </Box>
  );
};

export default ProfilePage;