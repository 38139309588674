import React, { useState } from 'react';
import { Box, Button, Container, TextField, Typography, Link, Grid, Paper, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { login } from '../services/authService';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await login(email, password);
      navigate('/home');
    } catch (err) {
      console.error(err.message);
      setError(err.message);
    }
  };

  return (
    <div className="overlay">
      <Container maxWidth="sm">
        <Paper elevation={10} sx={{ padding: 4, borderRadius: 2 }}>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">

            {/* Logo */}
            <Avatar 
              alt="Diesel Prices Logo" 
              src="/logo.png" 
              variant="square"
              sx={{ width: 190, height: 90, marginBottom: 2, background: 'transparent' }} 
            />

            {/* Title */}
                      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 2 }}>
                        Diesel Prices
                      </Typography>


                      {/* Error message */}
                      {error ? (
                        <Typography variant="subtitle1" color="error" gutterBottom>
                          {error}
                        </Typography>
                      ) : (
                      <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                              Please sign in to continue
                                            </Typography>
                      )}
            <Box component="form" noValidate autoComplete="off" width="100%"  onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 3 }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{ mb: 3 }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
//                sx={{ height: '56px', fontSize: '16px' }} // Set height to 56px
                sx={{ mt: 3, mb: 2, height: '46px', fontSize: '16px' }}
              >
                Sign In
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link href="#" variant="body2">
                    Request Access
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default LoginPage;